window.$ = window.jQuery = require('jquery');
// import '../bootstrap-table/bootstrap-table'
import 'bootstrap-table'

import 'bootstrap-table/dist/locale/bootstrap-table-zh-TW'
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control'
// import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.css'
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie'

import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/plugin'
import 'jquery-ui/ui/widgets/mouse'
import 'jquery-ui/ui/widgets/sortable'

//import '../dragtable'
//import 'bootstrap-table/dist/extensions/reorder-columns/bootstrap-table-reorder-columns'
// import 'bootstrap-table/dist/extensions/sticky-header/bootstrap-table-sticky-header'

import 'bootstrap-table/dist/extensions/export/bootstrap-table-export'
// import 'tableexport.jquery.plugin/tableExport.min.js'
import '../tableExport.min.js'
import '../bootstrap-table-custom.js'
